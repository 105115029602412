<script>
  // @ts-check
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  import Form from "$lib/components/Form.svelte";

  import {
    buildFormFields,
    buildFormFieldGroups,
    buildCta,
    buildDefaultFormFields
  } from "$lib/utilities/contentTypes/form";
  import { getFullUrl } from "$lib/utilities/url-helpers";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;

  const {
    id,
    provider,
    layout,
    title,
    description,
    confirmationTitle,
    confirmationSubtitle,
    confirmationDescription,
    confirmationCta,
    redirectUrl
  } = data?.fields || {};

  const formFields = buildDefaultFormFields(data?.fields, $page?.url).concat(
    buildFormFields(data?.fields)
  );
  const formFieldGroups = buildFormFieldGroups(data?.fields);
  const confirmation = {
    title: confirmationTitle,
    subtitle: confirmationSubtitle,
    description: confirmationDescription,
    cta: buildCta(confirmationCta)
  };
  const fullRedirectUrl = getFullUrl(redirectUrl, $page?.url?.origin);
  const imageFields = data?.fields?.featuredImage?.fields;
  const theme = data?.fields?.theme || "lightGrey";
</script>

<Form
  class={className}
  {id}
  {provider}
  {layout}
  {theme}
  {title}
  {description}
  image={imageFields}
  {formFields}
  {formFieldGroups}
  {confirmation}
  redirectUrl={fullRedirectUrl}
/>
