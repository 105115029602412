<script>
  // @ts-check
  import { GlobalNavigation, Button } from "@ObamaFoundation/of-design-system";
  import { utilities } from "@ObamaFoundation/of-design-system";

  import { buildSrc, extractEntryContentType } from "$lib/utilities";
  import { heroThemeStore } from "$lib/stores";
  import { onMount, getContext } from "svelte";

  import SearchPanel from "./SearchPanel.svelte";
  import SearchIconButton from "./SearchIconButton.svelte";
  import { isExternalUrl } from "$lib/utilities/url-helpers";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  export let data;
  export let activePrimaryUrl;
  export let topBannerHeight = 0;

  const menuItems = data.children.filter((child) => extractEntryContentType(child) === "menu");
  const mainNavigation = [];
  let utilityNavigation = [];

  /** @type {{fullTextSearch?: boolean, querySuggestions?: boolean}} */
  const featureFlags = getContext("featureFlags") ?? {};

  const Constants = /** @type {const} */ {
    scrollThreshold: 1,
    maximumTimingBeforeAReset: 1000
  };

  $: currentPath = $page?.url.pathname;

  const pagesToHideDonateButton =
    import.meta.env.VITE_PAGES_TO_HIDE_DONATE?.split(",")?.filter(Boolean) || [];

  menuItems.forEach((child) => {
    const link = {
      label: child?.fields?.trigger?.fields?.text ?? child?.trigger?.fields?.text,
      url:
        child?.fields?.trigger?.fields?.destination?.fields?.url ??
        child?.trigger?.fields?.destination?.fields?.url
    };

    const secondarySections = [];

    child?.fields?.items?.forEach((item) => {
      if (item?.fields?.type === "Static Menu") {
        secondarySections.push({
          type: "section",
          links: item?.fields?.items.map((link) => {
            return {
              id: `primary-utility-nav-${link?.sys.id}`,
              label: link?.fields?.text,
              url: link?.fields?.url,
              isExternal: isExternalUrl(link?.fields.url)
            };
          })
        });
      } else if (item?.sys?.contentType?.sys?.type === "Link") {
        secondarySections.push({
          type: "card",
          card: {
            label: item?.fields?.text,
            linkUrl: item?.fields?.destination?.fields?.url,
            isExternal: isExternalUrl(item?.fields?.destination?.fields.url),
            imageUrl: buildSrc(item?.fields?.image),
            alt: item?.fields?.image?.fields?.description
          }
        });
      }
    });

    mainNavigation.push({
      link,
      secondarySections
    });
  });

  onMount(() => {
    // short circuit
    if (pagesToHideDonateButton.includes(currentPath)) {
      return;
    }

    data.children
      .filter((child) => extractEntryContentType(child) === "linkComponent")
      .forEach((child) => {
        const isDonateButton = child.fields?.destination?.fields.url.includes("?form");
        const donateButtonOverrideUrl = $page?.data.pageEntry.fields.donateButtonOverrideUrl ?? "";
        if (isDonateButton) {
          utilityNavigation.push({
            id: `primary-utility-nav-${child.sys.id}`,
            label: child.fields?.text,
            url: donateButtonOverrideUrl
              ? `${donateButtonOverrideUrl}`
              : child.fields?.destination.fields?.url
          });
        }
      });
    // eslint-disable-next-line no-self-assign
    utilityNavigation = utilityNavigation; // trigger component re-rendering
  });

  let isHidden = false;
  let isExpanded = false;
  let scrollYPosition;
  let navHeight;
  let delayThemeFadeOnNavBar = false;
  let animateNavBarItems = false;

  $: hasScrolledPastNav = scrollYPosition > navHeight + topBannerHeight;

  onMount(() => {
    // Only animate the nav bar items after the first load
    animateNavBarItems = true;
  });

  const handleOnResize = () => {
    navHeight = parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue("--nav-height")
    );
  };

  /** @type {
    {
      initialScrollPoint: number;
      lastScrollEvaluation: number | null;
      isScrollBeingEvaluated: boolean;
    }
  } */
  let scrollEvaluation = {
    initialScrollPoint: 0,
    lastScrollEvaluation: Date.now(),
    isScrollBeingEvaluated: false
  };

  const resetScrollEvaluation = () => {
    scrollEvaluation = {
      isScrollBeingEvaluated: false,
      initialScrollPoint: scrollYPosition,
      lastScrollEvaluation: null
    };
  };

  const handleOnScroll = () => {
    const timeFromLastScrollEvaluation = Date.now() - scrollEvaluation.lastScrollEvaluation;

    if (
      !scrollEvaluation.isScrollBeingEvaluated ||
      timeFromLastScrollEvaluation > Constants.maximumTimingBeforeAReset
    ) {
      scrollEvaluation = {
        lastScrollEvaluation: Date.now(),
        initialScrollPoint: scrollYPosition,
        isScrollBeingEvaluated: true
      };
    }

    const hasUserScrolledDown =
      scrollYPosition > scrollEvaluation.initialScrollPoint + Constants.scrollThreshold;

    const hasUserScrolledUp =
      scrollYPosition < scrollEvaluation.initialScrollPoint - Constants.scrollThreshold;

    // Manage sticky positioning when user has not scrolled past the nav
    if (!hasScrolledPastNav) {
      if (hasUserScrolledDown) {
        isHidden = true;
      }
      return;
    }

    if (hasUserScrolledDown && !isHidden) {
      isHidden = true;
      resetScrollEvaluation();
    } else if (hasUserScrolledUp && isHidden) {
      isHidden = false;
      resetScrollEvaluation();
    }
  };

  // Theme management
  let heroTheme;
  let isSearchPanelVisible = false;

  // Used to retrieve the current Home Hero background color to set the theme of the Global Navigation.
  heroThemeStore.subscribe((value) => {
    heroTheme = value;
  });

  $: defaultNavTheme = hasScrolledPastNav ? "white" : heroTheme;
  $: currentNavTheme = isSearchPanelVisible || isExpanded ? "lightGrey" : defaultNavTheme;
  $: theme = utilities.getTheme(currentNavTheme);

  const handleUtilityLinkClick = (utilityLink) => {
    if (utilityLink.url.includes("?form") || utilityLink.label === "Donate") {
      isExpanded = false;
    }
  };

  onMount(() => {
    handleOnResize();
    handleOnScroll();
  });

  $: {
    if (hasScrolledPastNav) {
      delayThemeFadeOnNavBar = false;
    } else {
      delayThemeFadeOnNavBar = true;

      // Allow animation to complete before resetting
      setTimeout(() => {
        delayThemeFadeOnNavBar = false;
      }, 500);
    }
  }

  $: transitionClass = animateNavBarItems ? "transition-all duration-[150ms] ease-nav-easing" : "";

  const displaySearchButton = featureFlags.fullTextSearch;
  const handleSearchOpen = () => {
    isSearchPanelVisible = true;
  };
  const handleSearchClose = () => {
    isSearchPanelVisible = false;
  };
</script>

<svelte:window
  on:scroll={handleOnScroll}
  on:resize={handleOnResize}
  bind:scrollY={scrollYPosition}
/>

<GlobalNavigation
  bind:theme={currentNavTheme}
  bind:isHidden
  bind:isExpanded
  {hasScrolledPastNav}
  {mainNavigation}
  {delayThemeFadeOnNavBar}
  {animateNavBarItems}
  currentRoute={activePrimaryUrl}
  on:searchClose={handleSearchClose}
  {isSearchPanelVisible}
>
  <svelte:fragment slot="utilityNavigation">
    {#if displaySearchButton}
      <SearchIconButton
        focusOutlineColor={theme?.focusOutlineColor}
        hoverTextColor={theme?.hoverTextColor}
        on:click={handleSearchOpen}
      />
    {/if}
    {#each utilityNavigation as utilityLink, index}
      <Button
        id={utilityLink.id}
        bind:theme={currentNavTheme}
        data-transition-focus={`${utilityLink.label.replace(" ", "")}-${index}`}
        variant="filled"
        buttonClass="override-[nav]:px-3 override-[nav]:py-2 override-[nav]:cta-sm {transitionClass}"
        label={utilityLink.label}
        url={utilityLink.url}
      />
    {/each}
  </svelte:fragment>
  <svelte:fragment slot="utilityNavigationMobile">
    {#each utilityNavigation as utilityLink, index}
      <a
        data-sveltekit-reload
        data-transition-focus={`${utilityLink.label.replace(" ", "")}-${index}`}
        href={utilityLink.url}
        on:click={() => handleUtilityLinkClick(utilityLink)}
        class="cta-lg focus-visible:outline-offset-2 focus-visible:outline-dashed"
      >
        {utilityLink.label}
      </a>
    {/each}
    {#if displaySearchButton}
      <SearchIconButton
        focusOutlineColor={theme?.focusOutlineColor}
        hoverTextColor={theme?.hoverTextColor}
        on:click={handleSearchOpen}
      />
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="searchPanel">
    <SearchPanel bind:isSearchPanelVisible currentTheme={currentNavTheme} />
  </svelte:fragment>
</GlobalNavigation>
