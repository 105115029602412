/**
 * @typedef {"test" | "apply"} InforApiMode
 *
 * `test` mode will validate request and return a mocked response;
 * to actually create an event lead, use `apply` mode
 */

const INTEGRATION_API = {
  baseUrl: import.meta.env.VITE_INTEGRATION_API_URL,
  endpoints: {
    createEventLead: "/api/infor/create-event-lead"
  }
};

/**
 *
 * @param {Object} fields
 * @param {InforApiMode} mode
 * @returns
 */
export const createEventLead = async (fields = {}, mode = "test") => {
  try {
    const res = await fetch(
      `${INTEGRATION_API.baseUrl}${INTEGRATION_API.endpoints.createEventLead}?mode=${mode}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(fields)
      }
    );

    if (res.ok) {
      return {
        status: "success"
      };
    } else {
      const firstError = (await res.json())?.messages[0];
      // TODO: log error to Datadog
      console.error("error: ", firstError);
      throw new Error(`${res.status} ${firstError}`);
    }
  } catch (error) {
    // TODO: log error to Datadog
    console.error("error: ", error);
    throw error;
  }
};
